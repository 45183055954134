<template>
  <component
    :is="noLink ? 'div' : TheNuxtLink"
    class="user-card flex items-center py-2 rounded-md gap-4 min-w-0"
    :to="noLink || !user.attributes ? undefined : linkTo(user)"
  >
    <UserAvatar
      :user="user"
      class="user-card__avatar flex-shrink-0"
      :size="avatarSize || 40"
      :frame="frame"
      :no-frame="noFrame"
      no-link
    />
    <div class="user-card__username min-w-0">
      <BadgeUsername
        class="mr-auto inline-flex max-w-full"
        :class="{
          'font-medium': plain,
          'font-bold': !plain,
        }"
        :badges="actualBadges"
        :official
        :verified
      >
        {{ displayName }}
        <template #after>
          <slot name="postDisplayName"></slot>
        </template>
      </BadgeUsername>
      <div class="text-sm flex items-center">
        <slot name="subtext"
          ><span class="inline-block opacity-70 truncate"
            >@{{ username }}</span
          ></slot
        >
        <slot name="postUsername"></slot>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { parseUserForDisplay } from "~/utils/display/user";
import {
  type BadgeRelation,
  type CreatorRelation,
  type FrameEntity,
  type FrameRelation,
  type LeaderRelation,
  type MemberRelation,
  type PopulateRelationship,
  type UserEntity,
  type UserRelation,
  type UserRelations,
} from "~/src/api";
import BadgeUsername from "~/components/badge/BadgeUsername.vue";

const TheNuxtLink = resolveComponent("TheNuxtLink");

interface Props {
  user:
    | UserEntity
    | UserRelation
    | MemberRelation
    | LeaderRelation
    | CreatorRelation
    | UserRelations;
  frame?: FrameEntity | PopulateRelationship<FrameRelation>;
  badges?: PopulateRelationship<BadgeRelation>[];
  avatarSize?: number;
  noFrame?: boolean;
  noLink?: boolean;
  plain?: boolean;
}

const { user, badges } = defineProps<Props>();

const {
  username,
  displayName,
  verified,
  official,
  badges: parsedBadges,
} = parseUserForDisplay(user, "small");

const actualBadges = computed(() => badges ?? parsedBadges);
</script>
